// 存放数据,永久

// 1.0 行业类型
export const IndustryTypes = [
  {
    value: 1,
    label: "其他",
  },
  {
    value: 2,
    label: "IT",
  },
  {
    value: 3,
    label: "健康",
  },
  {
    value: 4,
    label: "母婴",
  },
  {
    value: 5,
    label: "汽车",
  },
  {
    value: 6,
    label: "旅游",
  },
  {
    value: 7,
    label: "宠物",
  },
];

// 1.1 平台类型
export const PlatformTypes = [
  {
    id: 1,
    name: "小程序",
  },
  {
    id: 2,
    name: "WEB",
  },
  {
    id: 3,
    name: "公众号",
  },
  {
    id: 4,
    name: "APP",
  },
  {
    id: 5,
    name: "群组",
  },
];

// 1.2 我的应用所需数据
export const applicationAppTypes = [
  {
    name: "公众号",
    img: require("@/assets/images/appLications/gzh.png"),
    number: 0,
    id: 3,
  },
  {
    name: "APP",
    img: require("@/assets/images/appLications/app.png"),
    number: 0,
    id: 4,
  },
  {
    name: "WEB",
    img: require("@/assets/images/appLications/web.png"),
    number: 0,
    id: 2,
  },
  {
    name: "小程序",
    img: require("@/assets/images/appLications/xcx.png"),
    number: 0,
    id: 1,
  },
  {
    name: "群组",
    img: require("@/assets/images/appLications/qzu.png"),
    number: 0,
    id: 5,
  },
];

// 1.3 应用设置，跳转页面设置，参数说明
export const jumpParameters = [
  {
    name: "userid",
    type: "String",
    describe: ["合作社区会员唯一ID"],
  },
  {
    name: "projectid",
    type: "Int",
    describe: ["问卷ID（合作社区可以以此来区分不同的问卷)"],
  },
  {
    name: "status",
    type: "String",
    describe: [
      "会员答题状态。值为以下之一",
      "1.	‘c’:成功完成（实时)，即正常答完一份完整问卷。",
      "2.	‘s’:被甄别（实时)，答题过程中因为不符合问卷要求，而提前结束。",
      "3.	‘q’:配额满（实时)，由于对应抽样条件下已经有足够多的人回答问卷，而提前结束。（例如:男性、女性各需要100份，某个时候男性已经收集了100份，女性只收集10份，接下来第101个男性进来答题就会被告知配额满，而女性可以继续答题)",
      "4.	‘uc’:审核通过（非实时)，成功完成的答卷，通过了审核。",
      "5.	‘cn’:审核不通过（非实时)，成功完成的答卷，未通过审核。",
    ],
  },
  {
    name: "price",
    type: "money",
    describe: [
      "合作社区会员完成问卷获得的现金奖励（单位:元)",
      "注意:社区需要根据自己社区积分和现金比自行转换发放给会员。",
    ],
  },
  {
    name: "sn",
    type: "String",
    describe: [
      "校验码的生成方法",
      "md5(userid字符串 + projectid字符串 + status字符串 + price字符串 + key)",
      "例如:key = 7485fe2c-e437-4404-b514-9ab82c2afd27,userid = test1 ,projectid = 10292 ,status = c ,price = 1.20",
      "其对应生产的加密后的结果为:md5(test110292c1.207485fe2c-e437-4404-b514-9ab82c2afd27)等于7f4220cd4b275303656a1fb98384211a",
      "注意:以上参与校验码计算的参数均采用小写。",
    ],
  },
];

// 1.4 应用设置json示例代码
export const sampleCode = {
  status: 200,
  success: true,
  msg: "获取成功",
  msgDev: null,
  data: [
    {
      id: 1,
      number: "P20180620A",
      name: "对接测试项目",
      stateId: 0,
      state: "running",
      reward_type: 1,
      uri: "http://ai.wenjuanji.com/JoinSurveyInfo.aspx?webid=wenjuanji&pkg=198A10FFB028B527FC&uid=",
      url: "http://ai.wenjuanji.com/JoinSurveyInfo.aspx?webid=wenjuanji&pkg=198A10FFB028B527FC&uid=",
      quota: {
        id: 0,
        max_sample_c: 100,
        sex: {},
        age: {},
        city: {},
        district: {},
        personal_income: {},
        household_income: {},
      },
      sticky: false,
      ir: 100,
      length: 5,
      scale: 0.6,
      price: 0.01,
      screen_out_price: 0,
      quota_full_price: 0,
      extra_c_price: 0,
      award: 0.01,
      screen_out_award: 0,
      quota_full_award: 0,
      rate: 1,
      last_update_time: "2023-06-16 09:09:41",
      random: false,
      country: {
        id: 156,
        code: "CN",
        name: "中国大陆",
      },
    },
    {
      id: 23643,
      number: "P220627003",
      name: "电子产品调查",
      stateId: 0,
      state: "running",
      reward_type: 1,
      uri: "http://ai.wenjuanji.com/JoinSurveyInfo.aspx?webid=wenjuanji&pkg=XX62A8DBE3326D0C28FFD2EF462E922DB0&uid=",
      url: "http://ai.wenjuanji.com/JoinSurveyInfo.aspx?webid=wenjuanji&pkg=XX62A8DBE3326D0C28FFD2EF462E922DB0&uid=",
      quota: {
        id: 0,
        max_sample_c: 10,
        sex: {},
        age: {},
        city: {},
        district: {},
        personal_income: {},
        household_income: {},
      },
      sticky: false,
      ir: 30,
      length: 10,
      scale: 0.6,
      price: 4,
      screen_out_price: 0,
      quota_full_price: 0,
      extra_c_price: 0,
      award: 2.4,
      screen_out_award: 0,
      quota_full_award: 0,
      rate: 1,
      last_update_time: "2023-06-30 10:21:23",
      random: false,
      country: {
        id: 156,
        code: "CN",
        name: "中国大陆",
      },
    },
  ],
  extraData: {
    logo: "",
    unitName: "金币",
    minCpiEnabled: false,
    minCpi: 0,
    ratio: 200,
    fontColor: "",
    backgroundColor: "#EEEEEE",
    IRVisible: true,
    memberRewardVisible: true,
    scale: 0.7,
  },
};

// 1.5 json调查墙传入参数
export const jsonAfferentCodes = [
  {
    name: "app_id",
    type: "String",
    describe: ["问卷集提供，注册问卷平台后自动生成"],
  },
  {
    name: "user_id",
    type: "String",
    describe: ["合作社区会员唯一ID"],
  },
  {
    name: "entry_type",
    type: "String",
    describe: [" 默认为空 问卷参与类别（1-API）"],
  },
];

// 1.6 json调查墙响应参数
export const jsonResponseCodes = [
  {
    name: "id",
    type: "Int",
    describe: ["项目编号"],
  },
  {
    name: "number",
    type: "String",
    describe: ["项目编号"],
  },
  {
    name: "name",
    type: "String",
    describe: ["项目名称"],
  },
  {
    name: "state",
    type: "String",
    describe: [
      "项目状态:running:正在进行;pause:项目暂停;checking:数据检查中;end:项目结束;cancel:项目取消",
    ],
  },
  {
    name: "url",
    type: "String",
    describe: [
      "项目参与地址，例如https://ai.abcdefg.cn/wj.aspx?webid=wenjuanji&pkg=AB7263DC4ACF31CC&uid=",
    ],
  },
  {
    name: "quota",
    type: "String",
    describe: [
      "问卷目标人群条件:Sex:性别Age:年龄;City:城市;District:地区;personal_income:个人;收入household_income:家庭月收",
    ],
  },
  {
    name: "scale",
    type: "String",
    describe: [
      "和会员分成比例:例如:设定0.6 那么，如果项目奖励是10元，会员获得6元",
    ],
  },
  {
    name: "price",
    type: "String",
    describe: ["项目完成单价"],
  },
  {
    name: "award",
    type: "String",
    describe: ["会员奖励价格"],
  },
  {
    name: "screen_out_price",
    type: "String",
    describe: ["被甄别价格"],
  },
  {
    name: "quota_full_price",
    type: "String",
    describe: ["项目配额满价格"],
  },
  {
    name: "screen_out_award",
    type: "String",
    describe: ["被甄别会员奖励价格"],
  },
  {
    name: "quota_full_award",
    type: "String",
    describe: ["项目配额满价格会员奖励价格"],
  },
];

//1.7 登录后首页所需数据
export const homePageCardList = [
  {
    type: 1, //通过type判断背景颜色
    name: "点击量",
    number: 0,
    title: "总点击量",
    numbernumberAll: 88,
    img: require("@/assets/images/homePage/click.png"),
  },
  {
    type: 1, //通过type判断背景颜色
    name: "完成量",
    number: 0,
    title: "总完成量",
    numberAll: 88,
    img: require("@/assets/images/homePage/complete.png"),
  },
  {
    type: 1, //通过type判断背景颜色
    name: "不合格量",
    number: 0,
    title: "总不合格量",
    numberAll: 88,
    img: require("@/assets/images/homePage/unqualified.png"),
  },
  {
    type: 2, //通过type判断背景颜色
    name: "收益量",
    number: 0,
    title: "总收益量",
    numberAll: 88,
    img: require("@/assets/images/homePage/yield.png"),
  },
];
// 1.8 未登录首页所需数据
export const floatings = [
  {
    number: "1.3亿",
    title: "参与人次",
  },
  {
    number: "1350",
    title: "合作平台",
  },
  {
    number: "78",
    title: "覆盖国家",
  },
  {
    number: "115",
    title: "合作商",
  },
  {
    number: "75000",
    title: "执行项目",
  },
];
//1.9 未登录首页所需数据
export const imglist = [
  {
    type: 1,
    imgs: [
      require("@/assets/images/home/Tencent.png"),
      require("@/assets/images/home/Michelin.png"),
      require("@/assets/images/home/AVON.png"),
      require("@/assets/images/home/tsinghua.png"),
      require("@/assets/images/home/jd.png"),
    ],
  },
  {
    type: 2,
    imgs: [
      require("@/assets/images/home/Michelin.png"),
      require("@/assets/images/home/jd.png"),
      require("@/assets/images/home/AVON.png"),
      require("@/assets/images/home/Tencent.png"),
    ],
  },
];

// 2.0 json调查墙传入参数
export const jsonProjectSwitchCodes = [
  {
    name: "projectid",
    type: "Int",
    describe: ["问卷ID(合作社区可以以此来区分不同的问卷)"],
  },
  {
    name: "state",
    type: "Int",
    describe: ["问卷状态(0-开启,1-暂停)"],
  },
];

//2.1 首页本周与上周数据报表
export const homePageProgressList = [
  {
    name: "点击量",
    color: "#4578FF",
    number: 0,
    type: 1,
  },
  {
    name: "完成量",
    color: "#68EBB5",
    number: 0,
    type: 1,
  },
  {
    name: "不合格量",
    color: "#F4843E",
    number: 0,
    type: 1,
  },
  {
    name: "收益量",
    color: "#72DAFF",
    number: 0,
    type: 1,
  },
];
